// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/component/MonthLabel.client.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/component/MonthLabel.client.tsx");
  import.meta.hot.lastModified = "1705081685346.533";
}
// REMIX HMR END

import { useEffect, useState } from 'react';
let isHydrating = true;
export const MonthLabelClient = props => {
  _s();
  const [isHydrated, setIsHydrated] = useState(!isHydrating);
  useEffect(() => {
    isHydrating = false;
    setIsHydrated(true);
    console.log('[MonthLabelClient] useEffect');
  }, []);

  // get proportion of titleValue and subtitleValue
  console.log('[MonthLabelClient] ', JSON.stringify(props, null, 2));
  if (isHydrated) {
    return <a onClick={async () => {
      if (props.link !== null) {
        window.open(props.link, '_blank');
      }
    }}
    // href={props.link}
    // download={'invoice.pdf'}
    style={{
      fontFamily: "Motiva Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      color: "#CB2027",
      cursor: props.link !== null ? "pointer" : "default",
      opacity: props.link !== null ? 1 : 0.3
    }}>
                {props.currentItem.label}
            </a>;
  } else {
    return <div style={{
      fontFamily: "Motiva Sans",
      fontStyle: "normal",
      fontWeight: "bold",
      color: "#CB2027",
      opacity: 0.3
    }}>
                {props.currentItem.label}
            </div>;
  }
};
_s(MonthLabelClient, "3lWnC93cQcG2UpKXx2FMopoiisI=");
_c = MonthLabelClient;
var _c;
$RefreshReg$(_c, "MonthLabelClient");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;