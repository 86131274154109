// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/component/MonthReportItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/component/MonthReportItem.tsx");
  import.meta.hot.lastModified = "1705063115857.7148";
}
// REMIX HMR END

// MyEmailSubscribeForm.tsx

import { PlasmicComponent } from "@plasmicapp/loader-react";
const useProps = p => {
  const {
    titleValue,
    subtitleValue
  } = p;
  const r = {
    title: titleValue / subtitleValue,
    subtitle: subtitleValue / titleValue
  };
  if (r.title > 1) {
    return {
      ...p,
      subtitleWrapper: {
        style: {
          width: `${r.subtitle * 100}%`
        }
      }
    };
  } else {
    return {
      ...p,
      titleWrapper: {
        style: {
          width: `${r.title * 100}%`
        }
      },
      subtitleWrapper: {
        style: {
          width: `100%`
        }
      }
    };
  }
};
export function MonthReportItem(props) {
  _s();
  // get proportion of titleValue and subtitleValue
  return <PlasmicComponent component="MonthReportItem"
  // Ask Plasmic to use the original, Plasmic-generated
  // EmailSubscribeForm component
  forceOriginal componentProps={{
    ...useProps(props)
  }} />;
}
_s(MonthReportItem, "zOz81JAbhR95/c7H66hmKs689/U=", false, function () {
  return [useProps];
});
_c = MonthReportItem;
var _c;
$RefreshReg$(_c, "MonthReportItem");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;