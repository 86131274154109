// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/plasmic-init.ts"
);
import.meta.hot.lastModified = "1705081685346.533";
}
// REMIX HMR END

import { initPlasmicLoader } from "@plasmicapp/loader-react";
import {MonthReportItem} from "./component/MonthReportItem.tsx";
import {MonthLabelClient} from "./component/MonthLabel.client.tsx";
import {MonthReports} from "./component/MonthReports.tsx";

const plasmicConfig = {
    projects: [
        {
            id: "bqoWaFxea2XkmkcUKQscGg",  // ID of a project you are using
            token: "nPmPPA6TsU11XD6CxEgziqa9ptSzalCaxXn1z7wdDtHrr4wf4JidlqxhDAAi5xecbkrH0ZVSWu76QqaRsqg"  // API token for that project
        }
    ],
    // Fetches the latest revisions, whether or not they were unpublished!
    // Disable for production to ensure you render only published changes.
    preview: true,
}

console.log('[/plasmic-init]', 'config', plasmicConfig)

export const PLASMIC = initPlasmicLoader(plasmicConfig)

PLASMIC.substituteComponent(MonthReportItem, 'MonthReportItem')
PLASMIC.substituteComponent(MonthLabelClient, 'MonthLabel')

